import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { AuthContext } from "../provider/AuthProvider";

const useEditorialIssues = singletonHook(undefined, () => {
  const { editorialIssues, setEditorialIssues } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (editorialIssues !== undefined || !auth?.bearer) {
      return;
    }
    setEditorialIssues(null);
    axios
      .get<components["schemas"]["EditorialIssue"][]>("/editorialIssue/crud")
      .then((res) => {
        setEditorialIssues(
          res.data.reduce<IHashMap<components["schemas"]["EditorialIssue"]>>(
            (prev, editorialIssue) => {
              prev[editorialIssue.editorialIssueId as string] = editorialIssue;
              return prev;
            },
            {}
          )
        );
      });
  }, [auth?.bearer, setEditorialIssues, editorialIssues]);

  return editorialIssues;
});

export default useEditorialIssues;
